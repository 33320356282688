<template>
  <div id="information">
    <div class="top"></div>
    <div class="news wrapper">
      <div class="title">
        <router-link to="/dash">首页<span v-html="'&nbsp'"></span></router-link>
        <a href="javascript:void(0)">-<span v-html="'&nbsp'"></span>资讯</a>
      </div>
      <div class="content">
        <el-row>
          <el-col :span="18" class="left">
            <div class="newsList" v-if="isShow">
              <div class="newsItem">
                <div class="newsDate">
                  <router-link to="/news1">
                    <img
                      src="../../assets/images/information/news1.jpeg"
                      alt=""
                    />
                  </router-link>
                  <div class="date">
                    <div class="day">09</div>
                    <div class="year">2021-10</div>
                  </div>
                </div>
                <div class="newsArticle">
                  <div class="newsTitle">
                    <router-link to="/news1"
                      >住房租赁市场回暖
                      监管强化助力住房租赁市场规范发展</router-link
                    >
                  </div>
                  <div class="newsText">
                    <router-link to="/news1"
                      >今年三季度，此前受到新冠肺炎疫情影响的住房租赁市场逐渐回温，部分地市出现量价齐升情况，市场逐步修复。与此同时，住建部本月上旬公布了《住房租赁条例（征求意见稿）》，行业监管进一步强化。</router-link
                    >
                  </div>
                </div>
              </div>
              <div class="newsItem">
                <div class="newsDate">
                  <router-link to="/news2">
                    <img
                      src="../../assets/images/information/news2.jpeg"
                      alt=""
                    />
                  </router-link>
                  <div class="date">
                    <div class="day">11</div>
                    <div class="year">2020-10</div>
                  </div>
                </div>
                <div class="newsArticle">
                  <div class="newsTitle">
                    <router-link to="/news2"
                      >六省市整治住房租赁市场
                      住房租赁立法亟须按下“快进键”</router-link
                    >
                  </div>
                  <div class="newsText">
                    <router-link to="/news2"
                      >六省市集中整治住房租赁市场，也是对房地产市场供给侧进行改革与调整、优化与完善，不仅要让房地产市场秩序更加规范，促进房地产市场健康有序发展，也要通过供给侧供应水平的提高，让房地产市场不再因为购房比重太高、购房者太多而导致供需矛盾尖锐，影响房地产市场从调控转向长效管理。</router-link
                    >
                  </div>
                </div>
              </div>
              <div class="newsItem">
                <div class="newsDate">
                  <router-link to="/news3">
                    <img
                      src="../../assets/images/information/new3.jpeg"
                      alt=""
                    />
                  </router-link>
                  <div class="date">
                    <div class="day">08</div>
                    <div class="year">2021-07</div>
                  </div>
                </div>
                <div class="newsArticle">
                  <div class="newsTitle">
                    <router-link to="/news3">房屋租赁经纪行业制度</router-link>
                  </div>
                  <div class="newsText">
                    <router-link to="/news3"
                      >1998年住房制度改革以来，我国房地产经纪行业快速发展，据不完全统计,全国房地产经纪机构已逾5万家,从业人员超过100万人。二手房买卖市场份额不断扩大,而房屋租赁经纪业务一直都不是大多数房地产经纪企业的主要业务。由于房地产经纪行业准入门槛低、人员流动性大、市场秩序不甚规范,一些经纪机构存在发布虚假信息、协助当事人签订阴阳合同、挪用交易资金、吃差价等不规范行为,总体来看,我国房产中介行业的社会形象并不好。</router-link
                    >
                  </div>
                </div>
              </div>
              <div class="newsItem">
                <div class="newsDate">
                  <router-link to="/news4">
                    <img
                      src="../../assets/images/information/new4.jpeg"
                      alt=""
                    />
                  </router-link>
                  <div class="date">
                    <div class="day">20</div>
                    <div class="year">2020-12</div>
                  </div>
                </div>
                <div class="newsArticle">
                  <div class="newsTitle">
                    <router-link to="/news4"
                      >中央加快住房租赁市场立法</router-link
                    >
                  </div>
                  <div class="newsText">
                    <router-link to="/news4"
                      >12月16日正式闭幕的中央经济工作会议，对中国房地产市场提出了新的定位和工作部署，“房子是用来住的，不是用来炒的”这一表述，被看作是在减弱住房金融属性。同时，会议在抑制房地产市场泡沫、增加土地供应、落实人地挂钩等方面的部署，也决定着明年楼市调控的基调和市场走势。</router-link
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="newsList" v-else>
              <div class="newsItem">
                <div class="newsDate">
                  <router-link to="/news5">
                    <img
                      src="../../assets/images/information/新闻图片2.png"
                      alt=""
                    />
                  </router-link>
                  <div class="date">
                    <div class="day">06</div>
                    <div class="year">2021-07</div>
                  </div>
                </div>
                <div class="newsArticle">
                  <div class="newsTitle">
                    <router-link to="/news5"
                      >国务院办公厅印发《关于加快发展保障性租赁住房的意见》</router-link
                    >
                  </div>
                  <div class="newsText">
                    <router-link to="/news5"
                      >新华社北京7月2日电
                      国务院办公厅日前印发《关于加快发展保障性租赁住房的意见》（以下简称《意见》），明确保障性租赁住房基础制度和支持政策。
                      《意见》指出，以习近平新时代中国特色社会主义思想为指导，坚持房子是用来住的、不是用来炒的定位，突出住房的民生属性，扩大保障性租赁住房供给，缓解住房租赁市场结构性供给不足，推动建立多主体供给、多渠道保障、租购并举的住房制度，推进以人为核心的新型城镇化，促进实现全体人民住有所居。</router-link
                    >
                  </div>
                </div>
              </div>
            </div>
            <el-pagination
              background
              layout="prev, pager, next"
              :total="5"
              :page-size="pageSize"
              @current-change="currentChange"
              :current-page="currentPage"
            >
            </el-pagination>
          </el-col>

          <el-col :span="5" class="right">
            <div class="recommendTitle">推荐</div>
            <div class="recommendList">
              <div class="recommendItem">
                <router-link to="/help1">
                  <img src="../../assets/images/help/help1.jpeg" alt="" />
                  <div class="msg">
                    房屋装修注意事项(菜鸟看后装修无遗憾)--水电安装注意事项
                  </div>
                </router-link>
              </div>
              <div class="recommendItem">
                <router-link to="/help2">
                  <img src="../../assets/images/help/help3.jpeg" alt="" />
                  <div class="msg">租房也可变Dream House</div></router-link
                >
              </div>
              <div class="recommendItem">
                <router-link to="/help3">
                  <img src="../../assets/images/help/help2.png" alt="" />
                  <div class="msg">
                    房东利益如何最大化之合理装修
                  </div></router-link
                >
              </div>
              <div class="recommendItem">
                <router-link to="/help4">
                  <img src="../../assets/images/help/help4.png" alt="" />
                  <div class="msg">自建房的房屋租赁税计算</div></router-link
                >
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pageSize: 4,
      currentPage: 1,
      isShow: true
    }
  },
  mounted() {},
  methods: {
    currentChange(e) {
      this.currentPage = e
      e === 1 ? (this.isShow = true) : (this.isShow = false)
    }
  }
}
</script>

<style lang="less" scoped>
#information {
  .top {
    position: relative;
    top: 0;
    left: 0;
    height: 460px;
    width: 100%;
    background-image: url(../../assets/images/information/资讯背景.png);
    background-size: cover;
  }
  .news {
    .title {
      padding: 12px 0;
      margin-top: 20px;
      text-align: left;
      color: rgba(255, 255, 255, 0.7);
      border-bottom: 1px solid #d2d2d2;
      a {
        color: #5a5e66;
      }
    }
    .content {
      padding-bottom: 50px;
      .left {
        .el-pagination {
          padding: 50px 5px;
          text-align: center;
        }
        .newsList {
          color: #000;
          min-height: 300px;
          .newsItem {
            overflow: hidden;
            padding: 25px 5px;
            border-bottom: 1px solid #d2d2d2;
            display: flex;
            .newsDate {
              position: relative;
              img {
                width: 184px;
                height: 127px;
              }
              .date {
                position: absolute;
                top: 0;
                text-align: center;
                color: #fff;
                line-height: 20px;
                padding: 5px 10px;
                width: 50px;
                height: 40px;
                background-color: rgba(0, 0, 0, 0.582);
                .year {
                  font-size: 12px;
                }
                .day {
                  font-size: 18px;
                  line-height: 22px;
                }
              }
            }
            .newsArticle {
              padding-left: 15px;
              -webkit-box-sizing: border-box;
              box-sizing: border-box;
              text-align: left;
              width: calc(100% - 80px);
              .newsTitle {
                font-size: 22px;
                margin-bottom: 12px;
                line-height: 30px;
                :hover {
                  color: #00b0ff;
                }
                a {
                  color: #000;
                }
              }
              .newsText {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                overflow: hidden;
                font-size: 14px;
                line-height: 24px;
                a {
                  color: rgba(91, 95, 103, 0.8);
                }
              }
            }
          }
        }
      }
      .right {
        margin: 25px 0 0 50px;
        img {
          width: 200px;
          height: 115px;
        }
        background: #f2f6fa;
        min-height: 300px;
        .recommendTitle {
          color: #000;
          text-align: left;
          font-weight: bold;
          padding: 0 10px;
          margin: 20px 0;
          border-left: 3px solid #3b6798;
        }
        .recommendList {
          padding: 0 0 10px;
          .recommendItem {
            padding: 0 10%;
            margin-bottom: 15px;
            .msg {
              color: #717171;
              margin-top: 5px;
            }
          }
        }
      }
    }
  }
}
</style>
